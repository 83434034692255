import s from './Footer.module.scss'
import left from './left-flower.png'
import right from './right-flower.png'
import { ReactComponent as Insta } from './insta.svg'
import { ReactComponent as Face } from './face.svg'
import { ReactComponent as Vk } from './vk.svg'
import { useTranslation } from 'react-i18next'

export const Footer = () => {

    const { t } = useTranslation()

    return (
        <footer className={s.footer}>
            <img className={s.left} src={left} alt="" />
            <img className={s.right} src={right} alt="" />
            <div className={s.footer_body}>
                <div className={s.blocks}>
                    <div className={s.left_block}>
                        <span>{t('address')}</span>
                        <a href="mailto:office@cbg.org.by">office@cbg.org.by</a>
                        <a href="https://cbg.org.by" target="_blank" rel="noopener noreferrer">cbg.org.by</a>
                    </div>
                    <div className={s.middle_block}>
                        <a href={t('rule-link')} rel="noreferrer" target="_blank">{t('rule')}</a>
                        <a href={t('price-link')} rel="noreferrer" target="_blank">{t('price')}</a>
                        <a href={t('workingMode-link')} rel="noreferrer" target="_blank">{t('workingMode')}</a>
                    </div>
                    <div className={s.right_block}>
                        <div className={s.social}>
                            <a href="https://www.instagram.com/botanicalgardenminsk/" rel="noreferrer" target="_blank">
                                <Insta />
                            </a>
                            <a href="https://www.facebook.com/BotanicalGardenMinsk" rel="noreferrer" target="_blank">
                                <Face />
                            </a>
                            <a href="https://vk.com/botanicalgardenminsk" rel="noreferrer" target="_blank">
                                <Vk />
                            </a>


                        </div>
                        <div>
                            {t('developer')}: <a href="http://ssrlab.by" target="_blank" rel="noopener noreferrer">ssrlab.by</a>
                        </div>
                    </div>
                </div>
                <div className={s.under_block}>
                    © {t('botanicalGarden')}
                </div>
            </div>
        </footer>
    )
}